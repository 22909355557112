import { useRouter } from 'next/router';
import * as en from '../dictionaries/en.json';
import * as es from '../dictionaries/es.json';
import * as vi from '../dictionaries/vi.json';

export const dictionaries = {
  en,
  es,
  vi,
};

export type Locale = 'en' | 'es' | 'vi';

export default function useDictionary() {
  const router = useRouter();
  const locale: Locale = router.locale?.match(/en|es|vi/) ? router.locale as Locale : 'en';

  return dictionaries[locale || 'en'];
}
